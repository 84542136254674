<template>
    <div>
        <div v-if="bool" class="logon flex flex-x flex-y">注册成功！</div>
        <div v-else class="logon flex flex-x flex-y">正在跳转登录...</div>
    </div>
</template>
<script>
import domain from "@/utils/config";
import { getDomain } from "@/utils";
export default {
  name: 'login',
  data(){
    return{
      bool: false
    }
  },
  created:function(){
     if(sessionStorage.token){
        this.bool=true;
     }else{
        this.bool=false;
        let appid = getDomain();
        sessionStorage.setItem("isToken",true);
        window.location.href =  `http://${appid}${domain}?Callback=${encodeURI(window.location.href)}&source=3`;  
     }
  }
}
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'login';
</style>
